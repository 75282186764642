<template>
  <div class="px-8 mt-4">
    <v-dialog
      v-model="dialogModel"
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      class="poModal"
      scrollable
    >
      <v-card>
        <v-toolbar dark color="green darken-2" max-height="64">
          <v-toolbar-title> {{ processData.RISCODE }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="clickCloseMainDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text style="max-height: 900px">
          <v-row class="mt-5" no-gutters>
            <v-col cols="12" class="d-flex justify-center">
                <v-subheader class="font-weight-bold text-h6 text-center text-uppercase">
                    Dispense of Requisition and Issuance Slip
                </v-subheader>
            </v-col>
            <v-col cols="12" class="d-flex justify-center">
                <v-subheader class="font-weight-bold text-center">
                    <i>Creation of Inventory Custodian Slip & Property Acknowledgement Receipt</i>
                </v-subheader>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-data-table
                v-model="itemListSelected"
                :headers="headers0"
                :items="itemList"
                item-key="RISItemID"
                class="elevation-2"
                disable-pagination
                hide-default-footer
                show-select
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <span class="green--text text--darken-2"
                      >List Of Items</span
                    >
                    <v-spacer></v-spacer>
                    <v-switch
                    v-if="itemList.length > 0"
                    v-model="switchItemList"
                    inset
                    class="mr-3"
                    color="header"
                    hide-details
                    label="Hide 0 Qty"
                  ></v-switch>
                    <v-btn
                      outlined
                      color="green darken-2 mr-2"
                      class="mr-2"
                      @click="dispense()"
                      :disabled="itemListSelected.length == 0 || itemList_filtered.length == 0"
                    >
                      <v-icon>mdi-file-document-outline</v-icon>
                      Dispense
                    </v-btn>
                  </v-toolbar>
                </template>
                <template v-slot:body="{ items }">
                  <tbody v-for="item in items"  :key="item.RISItemID">
                    <tr v-if="item.Qty > 0 || !switchItemList">
                    <td>
                        <v-checkbox
                          v-model="itemListSelected"
                          :value="item"
                          style="margin: 0px; padding: 0px"
                          hide-details
                          v-if="item.Qty > 0"
                        />
                      </td>
                      <td>
                        {{ item.StockPropertyNo  }}
                      </td>
                      <td>
                        {{ item.ItemName }}
                      </td>
                      <td style="max-width: 200px">
                        {{ item.ItemDescription }}
                      </td>
                      <td>{{ item.UnitMeasureDescription }}</td>
                      <td>₱ {{ formatPrice(item.UnitCost) }}</td>
                      <td align="center">
                        {{ formatQty(item.OverallQty) }}
                      </td>
                      <td>
                        ₱
                        {{
                          formatPrice(
                            parseFloat(item.UnitCost) * parseFloat(item.OverallQty)
                          )
                        }}
                      </td>
                      <td align="center">
                        {{ formatQty(item.Qty) }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-col>
            <v-col cols="12" lg="6">
              <v-data-table
                :headers="headers"
                :items="itemList_ICS"
                item-key="RISItemID"
                class="elevation-5 my-4"
                disable-pagination
                hide-default-footer
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <span class="green--text text--darken-2 font-weight-bold"
                      >List Of Items (Inventory Custodian Slip)</span
                    >
                    <v-spacer></v-spacer>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.UnitCost`]="{ item }">
                  <span class="text-no-wrap">₱ {{ formatPrice(item.UnitCost) }}</span>
                </template>
                <template v-slot:[`item.Qty`]="{ item }">
                  {{ formatQty(item.Qty) }}
                </template>
                <template v-slot:[`item.Amount`]="{ item }">
                  <span class="text-no-wrap">₱ {{ formatPrice(parseFloat(item.UnitCost) * parseFloat(item.Qty)) }}</span>
                </template>
              </v-data-table>
            </v-col>
            <v-col cols="12" lg="6">
              <v-data-table
                :headers="headers"
                :items="itemList_PAR"
                item-key="RISItemID"
                class="elevation-5 my-4"
                disable-pagination
                hide-default-footer
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <span class="green--text text--darken-2 font-weight-bold"
                      >List Of Items (Property Acknowledgement Slip)</span
                    >
                    <v-spacer></v-spacer>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.UnitCost`]="{ item }">
                  <span class="text-no-wrap">₱ {{ formatPrice(item.UnitCost) }}</span>
                </template>
                <template v-slot:[`item.Qty`]="{ item }">
                  {{ formatQty(item.Qty) }}
                </template>
                <template v-slot:[`item.Amount`]="{ item }">
                  <span class="text-no-wrap">₱ {{ formatPrice(parseFloat(item.UnitCost) * parseFloat(item.Qty)) }}</span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

<v-dialog v-model="IPRDialog" width="1500" scrollable persistent>
        <v-card>
          <v-card-title class="white--text text-h5 green darken-2">
            <span>Dispense Items</span>
            <v-spacer></v-spacer>
            <v-btn color="white darken-2" icon text @click="closeDispense()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text style="max-height: 900px">
              <v-form @submit.prevent ref="IPRFormRef">
                <v-row class="mt-4">
                <v-col cols="12" v-if="itemList_ICS.length > 0">
                  <strong>ICS Details</strong>
                  <v-divider></v-divider>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-autocomplete
                      v-model="ICSDispenseType"
                      item-text="description"
                      item-value="id"
                      :rules="[formRules.required]"
                      :items="dispenseTypes"
                      color="header" class="mx-3"
                      label="Dispense Type (ICS)">
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field label="ICS Purpose" color="header" class="mx-3" v-model="formData.ICSPurpose" :rules="[formRules.required]"></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" v-if="itemList_PAR.length > 0">
                  <strong>PAR Details</strong>
                  <v-divider></v-divider>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-autocomplete
                      v-model="PARDispenseType"
                      item-text="description"
                      item-value="id"
                      :rules="[formRules.required]"
                      :items="dispenseTypes"
                      color="header" class="mx-3"
                      label="Dispense Type (PAR)">
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field label="PAR Purpose" dense color="header" class="mx-3 mt-5" :rules="[formRules.required]" v-model="formData.PARPurpose"></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                  <v-col cols="12">
                <v-col cols="12">
              <v-toolbar flat dense class="my-0">
                <v-row align="center">
                    <v-divider></v-divider>
                    <v-switch
                    v-if="itemList_ICS.length > 1"
                    v-model="switchICS"
                    inset
                    class="ml-3"
                    color="header"
                    hide-details
                    label="Distribute/Share All"
                  ></v-switch>
                </v-row>
              </v-toolbar>
              <v-data-table
                :headers="headers2_ICS"
                :items="itemList_ICS"
                item-key="RISItemID"
                class="elevation-5 my-4"
                disable-pagination
                hide-default-footer
              >
                <template v-slot:top>
                  <v-row no-gutters class="pa-5">
                    <v-col cols="12" md="6">
                    <span class="green--text text--darken-2 font-weight-bold"
                      >List Of Items (Inventory Custodian Slip)</span
                    >
                    </v-col>
                    <v-col cols="12" md="6">
                    <span v-if="itemList_ICS.length > 1 && switchICS">
                      <v-autocomplete
                           v-model="ICSAllUserID"
                            label="Person Accountable"
                            :rules="[formRules.required]"
                            dense
                            outlined
                            hide-details
                            v-if="ICSDispenseType == 1"
                            color="header"
                            :items="users"
                            item-text="name"
                            item-value="UserID"
                            type="text"
                      ></v-autocomplete>
                      <v-autocomplete
                        v-model="ICSAllUserID"
                        label="Person Accountable"
                        :rules="[formRules.required]"
                        dense
                        outlined
                        v-if="ICSDispenseType == 2"
                        color="header"
                        :items="users"
                        hide-details
                        item-text="name"
                        item-value="UserID"
                        small-chips
                        deletable-chips
                        multiple
                      ></v-autocomplete>
                    <!-- <v-btn
                          color="green darken-2"
                          class="mr-2"
                          dark
                          elevation="2"
                          small
                          @click="distributeAll(item)"
                          v-if="ICSDispenseType == 1"
                        >
                          Distribute All
                        </v-btn>
                        <v-btn
                          color="blue darken-2"
                          class="mr-2"
                          dark
                          elevation="2"
                          small
                          @click="shareAll(item)"
                          v-if="ICSDispenseType == 2"
                        >
                          Share All
                        </v-btn> -->
                    </span>
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:[`item.UnitCost`]="{ item }">
                  <span class="text-no-wrap">₱ {{ formatPrice(item.UnitCost) }}</span>
                </template>
                <template v-slot:[`item.Qty`]="{ item }">
                  {{ formatQty(item.Qty) }}
                </template>
                <template v-slot:[`item.Amount`]="{ item }">
                  <span class="text-no-wrap">₱ {{ formatPrice(parseFloat(item.UnitCost) * parseFloat(item.Qty)) }}</span>
                </template>
                <template v-slot:[`item.QtyUsed`]="{ item }">
                  {{formatQty(item.usedQty) || 0}}
                </template>
                <template v-slot:[`item.NumOfICS`]="{ item }">
                  {{getMRCount(item.MRItems, switchICS)}}
                </template>
                <template v-slot:[`item.Actions`]="{ item }">
                  <span v-if="!switchICS">
                        <v-btn
                          color="green darken-2"
                          class="mr-2"
                          dark
                          outlined
                          elevation="2"
                          small
                          @click="distribute(item)"
                          v-if="ICSDispenseType == 1"
                        >
                        <v-badge color="red" :content="formatQty(item.usedQty)" :value="item.usedQty">
                          Distribute
                        </v-badge>
                        </v-btn>
                        <v-btn
                          color="blue darken-2"
                          class="mr-2"
                          dark
                          outlined
                          elevation="2"
                          small
                          @click="share(item)"
                          v-if="ICSDispenseType == 2"
                        >
                        <v-badge color="red" :content="formatQty(item.usedQty)" :value="item.usedQty">
                          Share
                        </v-badge>
                        </v-btn>
                        </span>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <div class="mt-5">
                    <v-row v-for="(mr, index) in item.MRItems" :key="index" no-gutters>
                      <v-col cols="12" md="4" class="px-2">
                        <v-text-field
                          label="Model"
                          outlined
                          dense
                          color="header"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    </div>
                  </td>
                </template>
              </v-data-table>
            </v-col>
            <v-col cols="12">
              <v-toolbar flat dense>
                <v-row align="center">
                    <v-divider></v-divider>
                    <v-switch
                    v-if="itemList_PAR.length > 1"
                    v-model="switchPAR"
                    class="ml-3"
                    color="header"
                    inset
                    hide-details
                    label="Distribute/Share All"
                  ></v-switch>
                </v-row>
              </v-toolbar>
              <v-data-table
                :headers="headers2_PAR"
                :items="itemList_PAR"
                item-key="RISItemID"
                class="elevation-5 my-4"
                disable-pagination
                hide-default-footer
              >
                <template v-slot:top>
                  <v-row no-gutters class="pa-5">
                    <v-col cols="12" md="6">
                    <span class="green--text text--darken-2 font-weight-bold"
                      >List Of Items (Property Acknowledgement Receipt)</span
                    >
                    </v-col>
                    <v-col cols="12" md="6">
                    <span v-if="itemList_PAR.length > 1 && switchPAR" class="mt-6">
                      <v-autocomplete
                           v-model="PARAllUserID"
                            label="Person Accountable"
                            :rules="[formRules.required]"
                            dense
                            outlined
                            v-if="PARDispenseType == 1"
                            color="header"
                            hide-details
                            :items="users"
                            item-text="name"
                            item-value="UserID"
                            type="text"
                      ></v-autocomplete>
                      <v-autocomplete
                        v-model="PARAllUserID"
                        label="Person Accountable"
                        :rules="[formRules.required]"
                        dense
                        outlined
                        v-if="PARDispenseType == 2"
                        color="header"
                        :items="users"
                        hide-details
                        item-text="name"
                        item-value="UserID"
                        small-chips
                        deletable-chips
                        multiple
                      ></v-autocomplete>
                    <!-- <v-btn
                          color="green darken-2"
                          class="mr-2"
                          dark
                          elevation="2"
                          small
                          @click="distributeAll(item)"
                          v-if="PARDispenseType == 1"
                        >
                          Distribute All
                        </v-btn>
                        <v-btn
                          color="blue darken-2"
                          class="mr-2"
                          dark
                          elevation="2"
                          small
                          @click="shareAll(item)"
                          v-if="PARDispenseType == 2"
                        >
                          Share All
                        </v-btn> -->
                    </span>
                  </v-col>
                </v-row>
                </template>
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="item in items" :key="item.RISItemID">
                      <td>
                        {{ item.StockPropertyNo  }}
                      </td>
                      <td>
                        {{ item.ItemName }}
                      </td>
                      <td style="max-width: 200px">
                        {{ item.ItemDescription }}
                      </td>
                      <td align="center">{{ item.UnitMeasureDescription }}</td>
                      <td>₱ {{ formatPrice(item.UnitCost) }}</td>
                      <td align="center">
                        {{ formatQty(item.Qty) }}
                      </td>
                      <td>
                        ₱
                        {{
                          formatPrice(
                            parseFloat(item.UnitCost) * parseFloat(item.Qty)
                          )
                        }}
                      </td>
                      <td align="center">
                        {{formatQty(item.usedQty) || 0}}
                      </td>
                      <td align="center">
                        {{getMRCount(item.MRItems, switchPAR)}}
                      </td>
                      <td align="center">
                        <span v-if="!switchPAR">
                        <v-btn
                          color="green darken-2"
                          class="mr-2"
                          dark
                          outlined
                          elevation="2"
                          small
                          @click="distribute(item)"
                          v-if="PARDispenseType == 1"
                        >
                        <v-badge color="red" :content="formatQty(item.usedQty)" :value="item.usedQty">
                          Distribute
                        </v-badge>
                        </v-btn>
                        <v-btn
                          color="blue darken-2"
                          class="mr-2"
                          dark
                          outlined
                          elevation="2"
                          small
                          @click="share(item)"
                          v-if="PARDispenseType == 2"
                        >
                        <v-badge color="red" :content="formatQty(item.usedQty)" :value="item.usedQty">
                          Share
                        </v-badge>
                        </v-btn>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-if="itemList_PAR.length == 0">
                    <tr><td :colspan="headers2_PAR.length" align="center">No data available</td></tr>
                  </tbody>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <div class="mt-5">
                    <v-row v-for="(mr, index) in item.MRItems" :key="index" no-gutters>
                      <v-col cols="12" md="4" class="px-2">
                        <v-text-field
                          label="Model"
                          outlined
                          dense
                          color="header"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    </div>
                  </td>
                </template>
              </v-data-table>
            </v-col>
                  </v-col>
                </v-row>
              </v-form>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-2" class="mr-1" outlined @click="closeDispense()">Cancel</v-btn>
              <v-btn color="green darken-2 white--text" @click="submitDispense()" :loading="submitbtn"><v-icon>mdi-check-circle</v-icon>Submit</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

<v-dialog v-model="DSDialog" width="1300" scrollable persistent>
        <v-card>
          <v-card-title class="white--text text-h5 green darken-2">
            <span v-if="DSType == 1">Distribute Items</span>
            <span v-if="DSType == 2">Share Items</span>
            <v-spacer></v-spacer>
            <v-btn color="white darken-2" icon text @click="cancelDS()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text style="max-height: 900px">
              <v-form @submit.prevent ref="DSFormRef">
                <v-row class="mt-4">
                <v-col cols="12">
                  <v-banner>
                    <v-icon
                      slot="icon"
                      color="warning"
                      size="36"
                    >
                      mdi-clipboard-check-outline
                    </v-icon>
                    <h4 class="mb-1">ITEM NAME</h4>
                    {{distributeData.ItemName}}
                    <!-- <template v-slot:actions>
                      <v-btn
                        color="primary"
                        text
                      >
                        Connection Settings
                      </v-btn>
                    </template> -->
                  </v-banner>
                  <v-banner>
                    <v-icon
                      slot="icon"
                      color="warning"
                      size="36"
                    >
                      mdi-playlist-edit
                    </v-icon>
                    <h4 class="mb-1">ITEM DESCRIPTION</h4>
                    <v-textarea
                      v-if="editDescription"
                      outlined
                      dense
                      hide-details
                      v-model="distributeData.ItemDescription"
                      color="header"
                    ></v-textarea>
                    <span v-if="!editDescription">{{distributeData.ItemDescription}}</span>
                    <template v-slot:actions>
                      <v-btn
                        color="header"
                        icon
                        @click="editDescription = !editDescription"
                      >
                        <v-icon v-if="!editDescription">mdi-pencil-box-multiple</v-icon>
                        <v-icon v-if="editDescription">mdi-check-circle</v-icon>
                      </v-btn>
                    </template>
                  </v-banner>
                  <v-toolbar flat>
                  <v-toolbar-title class="font-weight-bold">
                    <span v-if="tabs == 0">Assign Items</span>
                    <span v-if="tabs == 1">Edit Items</span>
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <div>
                    <v-tabs
                      v-model="tabs"
                      centered
                      color="header"
                      class="d-none"
                    >
                      <v-tab>
                        Assign Items
                      </v-tab>
                      <v-tab>
                        Edit Items
                      </v-tab>
                    </v-tabs>
                    <span class="font-size-16"> Remaining Qty: <b>{{computeQty(distributeData.MRItems,distributeData.Qty) > 0 ? computeQty(distributeData.MRItems,distributeData.Qty) : '0'}} {{distributeData.UnitMeasureDescription}}</b></span>
                  </div>
                </v-toolbar>
                <!-- <v-banner
                  single-line
                >
                  <span class="font-size-16"> Remaining Qty: <b>{{computeQty(distributeData.MRItems,distributeData.Qty) > 0 ? computeQty(distributeData.MRItems,distributeData.Qty) : '0'}} {{distributeData.UnitMeasureDescription}}</b></span>
                </v-banner> -->

                <v-tabs-items v-model="tabs">
                  <v-tab-item>
                    <v-card-text class="grey lighten-4">
                      <v-sheet
                        max-width="1500"
                        class="my-3"
                      >
                      <v-form @submit.prevent ref="AssignForm">
                      <v-row class="mx-5 pt-5 mt-n3" no-gutters>
                        <v-col cols="12" md="6" class="pa-1">
                          <v-text-field
                            v-model="formData2.Qty"
                            label="Quantity"
                            @change="validateQty(parseFloat(distributeData.Qty) + parseFloat(editItemData.Qty),formData2.Qty)"
                            :rules="[formRules.noNegativeNumberAllowed, formRules.numberRequired, validateQty(parseFloat(distributeData.Qty) + parseFloat(editItemData.Qty),formData2.Qty)]"
                            outlined
                            dense
                            color="header"
                            type="number"
                            v-if="editItemData"
                          ></v-text-field>
                          <v-text-field
                            v-model="formData2.Qty"
                            label="Quantity"
                            @change="validateQty(distributeData.Qty,formData2.Qty)"
                            :rules="[formRules.noNegativeNumberAllowed, validateQty(distributeData.Qty,formData2.Qty), formRules.numberRequired]"
                            outlined
                            dense
                            color="header"
                            type="number"
                            v-else
                          ></v-text-field>
                        </v-col>
                         <v-col cols="12" md="6" class="pa-1">
                          <v-autocomplete
                           v-model="formData2.UserID"
                            label="Person Accountable"
                            :rules="[formRules.required]"
                            dense
                            outlined
                            v-if="DSType == 1"
                            color="header"
                            :items="users"
                            item-text="name"
                            item-value="UserID"
                            type="text"
                          ></v-autocomplete>
                          <v-autocomplete
                           v-model="formData2.UserID"
                            label="Person Accountable"
                            :rules="[formRules.required]"
                            dense
                            outlined
                            v-if="DSType == 2"
                            color="header"
                            :items="users"
                            item-text="name"
                            item-value="UserID"
                            chips
                            deletable-chips
                            multiple
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                          <v-row>
                            <v-col cols="12" md="8">
                              <v-btn
                                color="header"
                                class="white--text"
                                dense
                                block
                                @click="addItem()"
                                v-if="editItemData == null"
                              >
                              <v-icon class="mr-1">mdi-plus-circle</v-icon>
                                Add Item
                              </v-btn>
                              <v-btn
                                color="header"
                                class="white--text"
                                dense
                                block
                                v-if="editItemData != null"
                                @click="updateItem()"
                              >
                              <v-icon class="mr-1">mdi-pencil-box-outline</v-icon>
                                Update Item
                              </v-btn>
                            </v-col>
                            <v-col cols="12" md="4">
                              <v-row>
                                <v-col v-if="editItemData">
                                  <v-btn
                                    color="grey"
                                    class="white--text"
                                    dense
                                    block
                                    @click="cancelUpdate()"
                                  >
                                  <v-icon class="mr-1">mdi-close</v-icon>
                                    Cancel Update
                                  </v-btn>
                                </v-col>
                                <v-col>
                                  <v-btn
                                    color="grey"
                                    class="white--text"
                                    dense
                                    block
                                    @click="resetForm()"
                                  >
                                  <v-icon class="mr-1">mdi-backspace-outline</v-icon>
                                    Reset
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      </v-form>
                      <v-row>
                        <v-col cols="12">
                          <v-data-table
                          :headers="headers3"
                          :items="MRItems"
                          :items-per-page="5"
                          :expanded.sync="expanded"
                          item-key="num"
                          show-expand
                          >
                          <template v-slot:[`item.Actions`]="{ item, index }">
                                <v-btn
                                  small
                                  color="grey"
                                  icon
                                  @click="editItem(item, index)"
                                >
                                <v-icon>mdi-pencil-outline</v-icon>
                                </v-btn>
                                <v-btn
                                  small
                                  color="grey"
                                  icon
                                  @click="deleteItem(index)"
                                >
                                  <v-icon color="red darken-2">mdi-trash-can-outline</v-icon>
                                </v-btn>
                          </template>
                          <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length">
                              <div class="mt-5">
                              <v-row no-gutters v-if="item.Qty > 5">
                                <v-col cols="12">
                                  <v-alert
                                    dense
                                    type="info"
                                    dark
                                    dismissible
                                    border="left"
                                  >
                                  You may input Brands, Models, and Serial No. in the designated fields below to fill in all fields efficiently.
                                  </v-alert>
                                </v-col>
                                <v-col cols="12" md="4" class="px-2">
                                  <v-textarea
                                    v-model="item.MRData_str.Brand"
                                    label="Enter Brands in Comma-Separated here..."
                                    outlined
                                    dense
                                    rows="3"
                                    color="header"
                                    @keyup="fillBrands(item)"
                                    :hint="hint_brand"
                                    persistent-hint
                                  >
                                  </v-textarea>
                                </v-col>
                                <v-col cols="12" md="4" class="px-2">
                                  <v-textarea
                                    v-model="item.MRData_str.Model"
                                    label="Enter Models in Comma-Separated here..."
                                    outlined
                                    dense
                                    rows="3"
                                    color="header"
                                    @keyup="fillModels(item)"
                                    :hint="hint_model"
                                    persistent-hint
                                  ></v-textarea>
                                </v-col>
                                <v-col cols="12" md="4" class="px-2">
                                  <v-textarea
                                    v-model="item.MRData_str.SerialNo"
                                    label="Enter Serial No. in Comma-Separated here..."
                                    outlined
                                    dense
                                    rows="3"
                                    color="header"
                                    @keyup="fillSerialNos(item)"
                                    :hint="hint_serial_no"
                                    persistent-hint
                                  ></v-textarea>
                                </v-col>
                              </v-row>
                              <v-divider class="mb-6"></v-divider>
                              <v-row v-for="(mr, index) in item.MRData" :key="index" no-gutters>
                                <v-col cols="12" md="4" class="px-2 d-flex">
                                  <span class="pa-2 font-size-14 font-weight-bold">{{index+1}}</span>
                                  <v-text-field
                                    v-model="mr.Brand"
                                    label="Brand"
                                    outlined
                                    dense
                                    color="header"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4" class="px-2">
                                  <v-text-field
                                    v-model="mr.Model"
                                    label="Model"
                                    outlined
                                    dense
                                    color="header"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4" class="px-2">
                                  <v-text-field
                                    v-model="mr.SerialNo"
                                    label="Serial Number"
                                    outlined
                                    dense
                                    color="header"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              </div>
                            </td>
                          </template>
                          </v-data-table>
                        </v-col>
                      </v-row>
                      </v-sheet>
                    </v-card-text>
                  </v-tab-item>
                  <v-tab-item>
                    <v-card-text class="grey lighten-4">
                      <v-sheet
                        max-width="1500"
                        height="300"
                        class="mx-auto"
                      >
                      HUHUHUHUHUHUHUHUHUHUHUHUHU
                      </v-sheet>
                    </v-card-text>
                  </v-tab-item>
                </v-tabs-items>

                </v-col>
                </v-row>
              </v-form>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
              <!-- <v-alert
                color="green"
                dense
                outlined
                type="success"
              >Item assigned Successfully</v-alert> -->
              <v-spacer></v-spacer>
              <v-btn color="grey darken-2" class="mr-1" outlined @click="cancelDS()"><v-icon>mdi-close-circle-outline</v-icon>Cancel</v-btn>
              <v-btn color="green darken-2 white--text" @click="saveDS()" :loading="submitbtn"><v-icon class="mr-1">mdi-check-circle</v-icon>Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

<!-- <v-dialog v-model="AssignItemDialog" width="400" scrollable>
        <v-card>
          <v-card-title class="white--text text-h5 green darken-2">
            <span>Assign Item</span>
            <v-spacer></v-spacer>
            <v-btn color="white darken-2" icon text @click="AssignItemDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text style="max-height: 700px">
            <form ref="qtyForm">
              <v-row class="my-3" no-gutters>
                <v-col cols="12">
                  <div class="font-size-17 mb-4">
                    Remaining Qty: <b>{{distributeData.Qty}} {{distributeData.UnitMeasureDescription}}</b>
                  </div>
                  <v-text-field
                    label="Quantity"
                    :rules="[formRules.noNegativeNumberAllowed, formRules.numberRequired]"
                    outlined
                    dense
                    color="header"
                    type="number"
                  ></v-text-field>
                  <v-divider class="mb-6"></v-divider>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Brand"
                    :rules="[formRules.required]"
                    outlined
                    dense
                    type="text"
                    color="header"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Model"
                    :rules="[formRules.required]"
                    outlined
                    dense
                    color="header"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Serial Number"
                    :rules="[formRules.required]"
                    outlined
                    dense
                    color="header"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    label="Person Accountable"
                    :rules="[formRulesrequired]"
                    dense
                    outlined
                    color="header"
                    :items="users"
                    item-text="name"
                    item-value="UserID"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </form>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-2" class="mr-1" outlined @click="AssignItemDialog = false">Cancel</v-btn>
              <v-btn color="green darken-2 white--text" @click="saveAI()" :loading="submitbtn"><v-icon class="mr-1">mdi-check-circle</v-icon>Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog> -->

      <v-dialog v-model="dialogConfirmSubmit" max-width="500px">
          <v-card>
            <v-card-title class="headline pt-5">Confirmation</v-card-title>
            <div class="mx-6">
              Are you sure you want to dispense 
              <span v-if="itemList.length == 1">this item?</span>
              <span v-if="itemList.length > 1">these items?</span>
            </div>
            <!-- <div class="mx-6 mt-5">
              <i><b>Note:</b> If the selected items have zero quantity used (distributed/shared), the item will be excluded automatically in the submission or creation of ICS, PAR, and RIS.</i>
            </div> -->
            <v-card-actions class="mt-5">
              <v-spacer></v-spacer>
              <v-btn color="grey darken-2" outlined @click="dialogConfirmSubmit = false">Cancel</v-btn>
              <v-btn color="green darken-2 white--text" @click="confirmDispense()"><v-icon>mdi-check-circle</v-icon> Submit</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogGenerate" max-width="700px" persistent scrollable>
          <v-card>
            <v-card-title class="headline pt-5">Generated Report</v-card-title>
            <v-card-text style="max-height: 900px">
            <!-- <v-container>
              <v-row wrap no-gutters align="center">
                  <v-col class="text-center">
                      <v-divider />
                  </v-col>
                  <v-col cols="5" class="text-center font-size-18"> Requisition and Issuance Slip </v-col>
                  <v-col class="text-center">
                      <v-divider />
                  </v-col>
              </v-row>
              <v-row
                class="fill-height"
                align-content="center"
                justify="center"
                v-if="!generatedRISID.RISID"
              >
                <v-col
                  class="text-subtitle-1 text-center"
                  cols="12"
                >
                  Generating RIS report
                </v-col>
                <v-col cols="6">
                  <v-progress-linear
                    color="header"
                    indeterminate
                    rounded
                    height="6"
                  ></v-progress-linear>
                </v-col>
              </v-row>
              <v-row
                class="fill-height"
                align-content="center"
                justify="center"
                v-if="generatedRISID.RISID"
              >
                <v-col
                  cols="12"
                  class="text-center"
                >
                  <v-btn icon @click="openFile('ris', generatedRISID.RISID)" outlined x-large fab>
                    <v-icon x-large>mdi-file-document-check</v-icon>
                  </v-btn>
                  <div class="mt-1 font-weight-bold">
                    {{generatedRISID.RISCODE}}
                  </div>
                </v-col>
              </v-row>
            </v-container> -->
            <v-container v-if="itemList_ICS.length > 0" class="mt-5">
              <v-row wrap no-gutters align="center">
                  <v-col class="text-center">
                      <v-divider />
                  </v-col>
                  <v-col cols="4" class="text-center font-size-18"> Inventory Custodian Slip </v-col>
                  <v-col class="text-center">
                      <v-divider />
                  </v-col>
              </v-row>
              <v-row
                class="fill-height"
                align-content="center"
                justify="center"
                v-if="generatedICSID.length == 0"
              >
                <v-col
                  class="text-subtitle-1 text-center"
                  cols="12"
                >
                  Generating ICS report
                </v-col>
                <v-col cols="6">
                  <v-progress-linear
                    color="header"
                    indeterminate
                    rounded
                    height="6"
                  ></v-progress-linear>
                </v-col>
              </v-row>
              <v-row
                class="fill-height"
                align-content="center"
                justify="center"
                v-if="generatedICSID.length > 0"
              >
                <v-col
                  class="text-center"
                  v-for="(item, index) in generatedICSID" :key="index"
                >
                  <v-btn icon @click="openFile('ics', item.ICSID)" outlined x-large fab>
                    <v-icon x-large>mdi-file-document-check</v-icon>
                  </v-btn>
                  <div class="mt-1 font-weight-bold">
                    {{item.ICSCODE}}
                  </div>
                </v-col>
              </v-row>
            </v-container>
            <v-container v-if="itemList_PAR.length > 0" class="mt-5">
              <v-row wrap no-gutters align="center">
                  <v-col class="text-center">
                      <v-divider />
                  </v-col>
                  <v-col cols="6" class="text-center font-size-18"> Property Acknowledgement Receipt </v-col>
                  <v-col class="text-center">
                      <v-divider />
                  </v-col>
              </v-row>
              <v-row
                class="fill-height"
                align-content="center"
                justify="center"
                v-if="generatedPARID.length == 0"
              >
                <v-col
                  class="text-subtitle-1 text-center"
                  cols="12"
                >
                  Generating PAR report
                </v-col>
                <v-col cols="6">
                  <v-progress-linear
                    color="header"
                    indeterminate
                    rounded
                    height="6"
                  ></v-progress-linear>
                </v-col>
              </v-row>
              <v-row
                class="fill-height"
                align-content="center"
                justify="center"
                v-if="generatedPARID.length > 0"
              >
                <v-col
                  class="text-center"
                  v-for="(item, index) in generatedPARID" :key="index"
                >
                  <v-btn icon @click="openFile('par', item.PARID)" outlined x-large fab>
                    <v-icon x-large>mdi-file-document-check</v-icon>
                  </v-btn>
                  <div class="mt-1 font-weight-bold">
                    {{item.PARCODE}}
                  </div>
                </v-col>
              </v-row>
            </v-container>
            </v-card-text>
            <v-card-actions class="mt-5">
              <v-spacer></v-spacer>
              <v-btn color="grey darken-2" outlined @click="clickCloseMainDialog()">Close</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

    <fade-away-message-component
      displayType="variation2"
      v-model="fadeAwayMessage.show"
      :message="fadeAwayMessage.message"
      :header="fadeAwayMessage.header"
      :top="fadeAwayMessage.top"
      :type="fadeAwayMessage.type"
    ></fade-away-message-component>
  </div>
</template>
    
    <script>
export default {
  props: {
    processData: {},
  },
  data: () => ({
    dialog: false,
    tabs: null,
    users: [],
    dialogModel: false,
    supplier: [],
    itemListSelected: [],
    formData: {
      Lists: [],
      ICSPurpose: '',
      PARPurpose: '',
      ICSList: '',
      PARList: '',
    },
    itemList: [],
    itemList_filtered: [],
    link: "",
    fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Added!",
      message: "",
      top: 10,
    },
    headers0: [
      {
        text: "Stock/ Property No.",
        align: "start",
        sortable: false,
        value: "StockPropertyNo",
      },
      {
        text: "Item Name",
        align: "start",
        sortable: false,
        value: "ItemName",
      },
      { text: "Description", value: "itemDescription", sortable: false },
      {
        text: "Unit Measure",
        value: "UnitMeasureDescription",
        sortable: false,
      },
      { text: "Unit Cost", value: "UnitCost", sortable: false },
      { text: "QTY (Overall)", value: "Qty", sortable: false },
      { text: "Amount", value: "Amount", sortable: false },
      { text: "QTY (Remaining)", value: "remainingQty", sortable: false },
    ],
    headers: [
      {
        text: "Stock/ Property No.",
        align: "start",
        sortable: false,
        value: "StockPropertyNo",
      },
      {
        text: "Item Name",
        align: "start",
        sortable: false,
        value: "ItemName",
      },
      { text: "Description", value: "ItemDescription", sortable: false },
      {
        text: "Unit Measure",
        value: "UnitMeasureDescription",
        sortable: false,
      },
      { text: "Unit Cost", value: "UnitCost", sortable: false },
      { text: "QTY", value: "Qty", sortable: false },
      { text: "Amount", value: "Amount", sortable: false },
    ],
    headers2_ICS: [
      {
        text: "Stock/ Property No.",
        align: "start",
        sortable: false,
        value: "StockPropertyNo",
      },
      {
        text: "Item Name",
        align: "start",
        sortable: false,
        value: "ItemName",
      },
      { text: "Description", value: "ItemDescription", sortable: false },
      {
        text: "Unit Measure",
        value: "UnitMeasureDescription",
        sortable: false,
      },
      { text: "Unit Cost", value: "UnitCost", sortable: false },
      { text: "QTY", value: "Qty", sortable: false },
      { text: "Amount", value: "Amount", sortable: false },
      { text: "Qty Used", value: "usedQty", sortable: false },
      { text: "# of ICS", value: "numOfICS", sortable: false },
      { text: "Action", value: "Actions", sortable: false, align: 'center' },
    ],
    headers2_PAR: [
      {
        text: "Stock/ Property No.",
        align: "start",
        sortable: false,
        value: "StockPropertyNo",
      },
      {
        text: "Item Name",
        align: "start",
        sortable: false,
        value: "ItemName",
      },
      { text: "Description", value: "ItemDescription", sortable: false },
      {
        text: "Unit Measure",
        value: "UnitMeasureDescription",
        sortable: false,
      },
      { text: "Unit Cost", value: "UnitCost", sortable: false },
      { text: "QTY", value: "Qty", sortable: false },
      { text: "Amount", value: "Amount", sortable: false },
      { text: "Qty Used", value: "usedQty", sortable: false },
      { text: "# of PAR", value: "numOfPAR", sortable: false },
      { text: "Action", value: "Actions", sortable: false, align: 'center' },
    ],
    // headers3: [
    //   {
    //     text: "Qty",
    //     align: "start",
    //     sortable: false,
    //     value: "Qty",
    //   },
    //   {
    //     text: "Brand",
    //     align: "start",
    //     sortable: false,
    //     value: "Brand",
    //   },
    //   {
    //     text: "Model",
    //     align: "start",
    //     sortable: false,
    //     value: "Model",
    //   },
    //   {
    //     text: "Serial Number",
    //     align: "start",
    //     sortable: false,
    //     value: "SerialNo",
    //   },
    //   {
    //     text: "Person Accountable",
    //     align: "start",
    //     sortable: false,
    //     value: "UserName",
    //   },
    //   {
    //     text: "Action",
    //     value: "actions",
    //     sortable: false,
    //     align: 'center',
    //   },
    // ],
    headers3: [
      {
        text: "#",
        align: " d-none",
        sortable: false,
        value: "num",
      },
      {
        text: "Qty",
        align: "start",
        sortable: false,
        value: "Qty",
      },
      {
        text: "Person Accountable",
        align: "start",
        sortable: false,
        value: "UserName",
      },
      {
        text: "Action",
        value: "Actions",
        sortable: false,
        align: 'center',
      },
    ],
    IPRDialog: false,
    itemList_ICS: [],
    itemList_PAR: [],
    submitbtn: false,
    dispenseTypes: [],
    ICSDispenseType: null,
    PARDispenseType: null,
    DSDialog: false,
    DSType: null,
    ICSReceivers: [],
    PARReceivers: [],
    distributeData: [],
    MRItems: [],
    formData2: {
      ItemID: null,
      Qty: null,
      UserID: null,
      UserName: null,
    },
    editItemIndex: null,
    editItemData: null,
    remainingQty: null,
    expanded: [],
    singleExpand: true,
    editDescription: false,
    dialogConfirmSubmit: false,
    dialogGenerate: false,
    generatedICSID : [],
    generatedPARID : [],
    switchICS: false,
    switchPAR: false,
    ICSAllUserID: null,
    PARAllUserID: null,
    switchItemList: true,
    hint_brand: '',
    hint_model: '',
    hint_serial_no: '',
  }),
  watch: {
    processData: {
      handler(data) {
        if (data.IARID) {
          this.getItems();
          this.dialogModel = true;
        }
      },
      deep: true,
    },
    itemListSelected: {
      handler(data) {
        this.formData.Lists = [];
        let ics = [];
        let par = [];
        data.forEach(item => {
          if(item.Qty > 0){
            this.formData.Lists.push(item)
            if(parseFloat(item.UnitCost) < 50000){
                ics.push(item);
            }
            else if(parseFloat(item.UnitCost) >= 50000){
                par.push(item);
            }
          }
        });
        this.itemList_ICS = ics;
        this.itemList_PAR = par;
      },
      deep: true,
    },
    ICSDispenseType: {
      handler() {
        this.itemList_ICS.forEach(item => {
          item.MRItems = []
          item.usedQty = 0
        });
        this.ICSAllUserID = null
        this.switchICS = false
      },
      deep: true,
    },
    PARDispenseType: {
      handler() {
        this.itemList_PAR.forEach(item => {
          item.MRItems = []
          item.usedQty = 0
        });
        this.PARAllUserID = null
        this.switchPAR = false
      },
      deep: true,
    },
    itemList_ICS: {
      handler() {
      },
      deep: true,
    },
    itemList_PAR: {
      handler() {
      },
      deep: true,
    },
    switchICS: {
      handler(data) {
        if(data){
          this.itemList_ICS.forEach(item => {
          item.MRItems = []
          item.usedQty = item.Qty
        });
        }
        else{
          this.itemList_ICS.forEach(item => {
          item.MRItems = []
          item.usedQty = 0
        });
        }
      },
      deep: true,
    },
    switchPAR: {
      handler(data) {
        if(data){
          this.itemList_PAR.forEach(item => {
          item.MRItems = []
          item.usedQty = item.Qty
        });
        }
        else{
          this.itemList_PAR.forEach(item => {
          item.MRItems = []
          item.usedQty = 0
        });
        }
      },
      deep: true,
    },
    switchItemList: {
      handler() {
      },
      deep: true,
    },
  },
  mounted() {
    this.getDispenseTypes();
    this.getPersonAcountable();
  },
  methods: {
    dispense() {
      this.IPRDialog = true;
    },
    closeDispense(){
      this.$refs.IPRFormRef.reset();
      this.IPRDialog = false;
    },
    closeDS(){
      this.$refs.AssignForm.reset();
      let ics = this.itemList_ICS
      let par = this.itemList_PAR
      this.itemList_ICS = [];
      this.itemList_PAR = [];
      this.itemList_ICS = ics
      this.itemList_PAR = par
      this.DSDialog = false;
    },
    cancelDS(){
      this.closeDS();
    },
    saveDS(){
      this.closeDS();
    },
    submitDispense(){
      if(this.$refs.IPRFormRef.validate() && this.formData.Lists.length > 0){
        this.dialogConfirmSubmit = true;
      }
    },
    confirmDispense(){
      if(this.$refs.IPRFormRef.validate() && this.formData.Lists.length > 0){
      this.submitDispenseConfirmed();
      this.dialogConfirmSubmit = false;
      // this.sampleGenerate();
      }
    },
    sampleGenerate(){
      this.dialogGenerate = true;
    },
    submitDispenseConfirmed() {
      if(this.$refs.IPRFormRef.validate() && this.formData.Lists.length > 0){
      this.dialogGenerate = true;
          if(this.itemList_ICS.length > 0){
            let data = new FormData();
            data.append("IARID", this.processData.IARID);
            data.append("RISID", this.processData.RISID);
            data.append("FundID", this.processData.FundID);
            data.append("SpecificFundSourceID", this.processData.SpecificFundSourceID);
            data.append("ICSPurpose", this.formData.ICSPurpose);
            data.append("DispenseTypeID", this.ICSDispenseType);
            data.append("UserID_all", this.ICSAllUserID);
            data.append("AllItem", this.switchICS ? 1 : 0);
            data.append("EUL", this.processData.DateReceivedEnd);
            data.append("List", JSON.stringify(this.itemList_ICS));
            this.axiosCall("/ics/add", "POST", data).then((res2) => {
              if(res2.data.status){
                setTimeout(() => {
                this.generatedICSID = res2.data.data;
                }, 600);
              }
              });
          }
          if(this.itemList_PAR.length > 0){
            let data = new FormData();
            data.append("IARID", this.processData.IARID);
            data.append("RISID", this.processData.RISID);
            data.append("FundID", this.processData.FundID);
            data.append("SpecificFundSourceID", this.processData.SpecificFundSourceID);
            data.append("PARPurpose", this.formData.PARPurpose);
            data.append("DispenseTypeID", this.PARDispenseType);
            data.append("UserID_all", this.PARAllUserID);
            data.append("AllItem", this.switchPAR ? 1 : 0);
            data.append("EUL", this.processData.DateReceivedEnd);
            data.append("List", JSON.stringify(this.itemList_PAR));
            this.axiosCall("/par/add", "POST", data).then((res2) => {
              if(res2.data.status){
                  setTimeout(() => {
                  this.generatedPARID = res2.data.data;
                  }, 600);
              }
              });
          }
      }
    },
    clickCloseMainDialog() {
      this.formData = [];
      this.ICSDispenseType = null;
      this.PARDispenseType = null;
      this.switchICS = false;
      this.switchPAR = false;
      this.ICSAllUserID = null;
      this.PARAllUserID = null;
      this.formData.Lists = [];
      this.itemListSelected = [];
      this.itemList_ICS = [];
      this.itemList_PAR = [];
      this.itemList = [];
      this.IPRDialog = false;
      this.dialogModel = false;
      this.dialogConfirmSubmit = false;
      this.dialogGenerate = false;
      this.eventHub.$emit("closeDispenseRIS", false);
    },
    getItems() {
      this.unpublishBtn = false;
      this.formData.Lists = [];
      let data = new FormData();
      data.append("RISID", this.processData.RISID);
      this.axiosCall("/ris/getItems", "POST", data).then((res) => {
        this.itemList_filtered = [];
        this.itemList = res.data.data.result;
        res.data.data.result.forEach(item => {
          if(item.Qty > 0){
            this.itemList_filtered.push(item)
          }
        });
        //this.users = res.data.data.users;
      });
    },
    getDispenseTypes() {
      this.axiosCall("/getAllDispenseType", "GET").then((res) => {
        this.dispenseTypes = res.data.data;
      });
    },
    distribute(data){
      this.DSType = 1
      this.remainingQty = this.computeQty(data.MRItems, data.Qty);
      this.distributeData = data
      this.MRItems = data.MRItems
      this.DSDialog = true;
    },
    share(data){
      this.DSType = 2
      this.remainingQty = this.computeQty(data.MRItems, data.Qty);
      this.distributeData = data
      this.MRItems = data.MRItems
      this.DSDialog =  true;
    },
    distributeAll(){
      this.DSType = 1
      this.DSDialog = true;
    },
    shareAll(){
      this.DSType = 2
      this.DSDialog =  true;
    },
    computeQty(items, overAllQty){
      let qty = 0;
      if(items){
        items.forEach(item => {
        qty = parseFloat(qty) + parseFloat(item.Qty)
      });
      }
      return parseFloat(overAllQty) - parseFloat(qty)
    },
    validateQty(overAllQty, currentQty){
      if(currentQty){
        let qty = 0;
      this.MRItems.forEach(item => {
        qty = parseFloat(qty) + parseFloat(item.Qty)
      });
      if(parseFloat(overAllQty) - parseFloat(qty) - parseFloat(currentQty) >= 0){
        return true;
      }
      else{
        return 'Quantity exceeded'
      }
      }
      else{
        return false
      }
    },
    getUserName(){
      let name = null;
      if(this.DSType == 1){
          this.users.forEach(item => {
          if(item.UserID == this.formData2.UserID){
            name = item.name;
          }
        });
        }
        else if(this.DSType == 2){
          let users = this.formData2.UserID
          this.users.forEach(item => {
          if(users.includes(item.UserID)){
            if(name == null){
              name = item.name;
            }
            else{
              name = name + ', ' + item.name;
            }
          }
        });
        }
        return name;
    },
    addItem(){
      if(this.$refs.AssignForm.validate()){
        let name = this.getUserName()
        let MRData = []
        for (let index = 0; index < this.formData2.Qty; index++) {
          MRData.push({
            Brand: null,
            Model: null,
            SerialNo: null
          });
        }
        let ln = this.MRItems.length
        let data = {
          num: this.MRItems.length > 0 ? this.MRItems[ln-1].num + 1 : 1,
          ItemID: this.distributeData.ItemID,
          Qty: this.formData2.Qty,
          UserID: this.formData2.UserID,
          UserName: name,
          MRData: MRData,
          MRData_str: {
            Brand: null,
            Model: null,
            SerialNo: null 
          }
        }
        this.MRItems.push(data)
        this.distributeData.usedQty = this.getUtilizedQty(this.MRItems)
        this.$refs.AssignForm.reset();
      }
    },
    editItem(item, index){
      this.formData2 = item;
      this.editItemIndex = index;
      this.editItemData = item;
    },
    updateItem(){
      if(this.$refs.AssignForm.validate()){
      let name = this.getUserName();
      this.MRItems[this.editItemIndex].Qty = this.formData2.Qty;
      this.MRItems[this.editItemIndex].UserID = this.formData2.UserID;
      this.MRItems[this.editItemIndex].UserName = name;
      this.editItemIndex = null;
      this.editItemData = null;
      this.formData2 = []
      // this.$refs.AssignForm.reset();
      }
    },
    cancelUpdate(){
      this.editItemIndex = null;
      this.editItemData = null;
      this.formData2 = []
    },
    resetForm(){
      this.formData2 = [];
    },
    deleteItem(index) {
      this.MRItems.splice(index, 1);
      if(index == this.editItemIndex){
        this.cancelUpdate();
      }
    },
    getUtilizedQty(data){
      let qty = 0
      data.forEach(item => {
        qty = qty + parseFloat(item.Qty)
      });
      return qty
    },
    openFile(type, id){
       window.open(this.apiUrl + "/" + type + "/report/" + id);
    },
    getMRCount(data, all){
      if(data){
        if(all){
          return '-'
        }
        else{
          return data.length
        }
      }
      else{
        return '-'
      }
    },
    fillBrands(data){
      let brands = data.MRData_str.Brand.split(",");
      data.MRData.forEach((item, index) => {
        item.Brand = brands[index];
      });
      if(brands.length > 0){
        this.hint_brand = "No. of Entries: " + brands.length;
      }
    },
    fillModels(data){
      let models = data.MRData_str.Model.split(",");
      data.MRData.forEach((item, index) => {
        item.Model = models[index];
      });
      if(models.length > 0){
        this.hint_model = "No. of Entries: " + models.length;
      }
    },
    fillSerialNos(data){
      let serial_nos = data.MRData_str.SerialNo.split(",");
      data.MRData.forEach((item, index) => {
        item.SerialNo = serial_nos[index];
      });
      if(serial_nos.length > 0){
        this.hint_serial_no = "No. of Entries: " + serial_nos.length;
      }
    },
    getPersonAcountable() {
      this.axiosCall("/getAllPersonsAccountable", "GET").then((res) => {
        this.users = res.data.data;
      });
    },
  },
};
</script>
        
    <style scoped>
.v-dialog--fullscreen,
.poModal {
  overflow-x: hidden !important;
}
</style>